.react-tel-input .selected-flag {
  color: white !important;          /* Optional: Change text color */
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.react-tel-input .selected-flag:hover {
  color: white !important;          /* Optional: Change text color */
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.selected-flag {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}